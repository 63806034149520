//create a react component named ShowParticipant that shows basic participant Info

import { Typography } from "@mui/material";
import PropTypes from "prop-types";

export default function ShowParticipant(props) {
  console.debug("ShowParticipant", props);
  const { participant } = props;
  console.debug("participant", participant);
  return (
    <div>
      <Typography variant="h4">Participant Information</Typography>
    </div>
  )
}

// Define prop types for ShowParticipant
ShowParticipant.propTypes = {
  participant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    age: PropTypes.number.isRequired,
  })
};
