// src/context/RegistrationContext.js
import { httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { functions } from '../firebase';
import { useParticipantData } from '../hooks/useParticipantData';
import {
  findOrCreateNewParticipant,
  findParticipantById,
  updateParticipantRegistration
} from '../services/RegistrationService';
import { clearCookies, getCookie, setCookie } from '../utils/cookieUtils';
import { useAlert } from './AlertContext'; // Assume you have an AlertContext for notifications

// Create the context
const RegistrationContext = createContext();


// Custom hook to use context
export const useRegistration = () => useContext(RegistrationContext);
// Provider component
export const RegistrationProvider = ({ children }) => {

  // Local function to get project ID from URL params or cookies, error if not found
  const getProjectId = () => {
    const cookie_projectId = getCookie('projectId');
    console.log("getProjectId: urlProjectId: %s, cookie_projectId: %s", urlProjectId, cookie_projectId);
    // check if the url projectId is present, if not check for cookie
    if(urlProjectId?.length > 0) {
      // check if cookie_projectId exists it is equal to urlProjectId, if not set cookie_projectId to urlProject
      // we will set projectId in cookie before redirecting to fitbit auth
      if (cookie_projectId && cookie_projectId !== urlProjectId) {
        setCookie('projectId', urlProjectId);
      }
      return urlProjectId;
    }

    return cookie_projectId;
  };

  // Retrieve URL parameters
  const { projectId: urlProjectId, pId: urlPId } = useParams();

  // State variables
  const [projectId] = useState(getProjectId());
  const [pid, setPid] = useState(urlPId?.length > 20 ? urlPId : null);
  const [participantId, setParticipantId] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  //const [error, setError] = useState(null);
  //const [success, setSuccess] = useState(null);

  // Use the AlertContext for notifications
  const { addAlert } = useAlert();

  // Initialize navigate function using useNavigate hook
  const navigate = useNavigate(); // useNavigate is a hook from react-router-dom

  // Use the custom hook to fetch participant data
  const [fetchedParticipant, loadingParticipant] = useParticipantData(participantId);

  // Centralized error handling function
  // @param {Error|string|null} err - The error object or message (optional)
  // @param {string} userMessage - The message to display to the user
  // Function to handle errors and display an alert to the user
  const handleError = useCallback((err = null, userMessage) => {
    if (err) {
      console.error(err);
    }
    addAlert('error', userMessage);
    setLoading(false);
  }, [addAlert]);

  // Effect to update participant and activeStep when fetchedParticipant changes
  useEffect(() => {
    setLoading(true);
    if (fetchedParticipant) {
      setParticipant(fetchedParticipant);

      // Set the active registration step from participant data
      setActiveStep(fetchedParticipant.registrationStep);
    } else if (!loadingParticipant && participantId) {
      // Handle case where participant data is not found
      // handleError(null, 'Participant data not found. Please restart the registration process.');
    }
    setLoading(false);
  }, [fetchedParticipant, loadingParticipant, participantId, handleError]);

  // On component mount, set the participant ID and PID from cookies
  useEffect(() => {
    const retrieveParticipantCookies = () => {
      setLoading(true);
      try {
        // Retrieve participant ID and PID from cookies
        const cookiePid = getCookie('pid');
        const cookieParticipantId = getCookie('participantId');

        // Only proceed if both cookie values exist
        if (cookiePid && cookieParticipantId) {
          console.debug("loadParticipantData: cookiePid: %s, cookieParticipantId: %s", cookiePid, cookieParticipantId);

          // Set PID and participant ID from cookies
          setPid(cookiePid);
          setParticipantId(cookieParticipantId);
        } else {
          // Handle case where cookies are missing
          //handleError(null, 'Session expired. Please restart the registration process.');
          //TODO: Decide what to do when cookies are missing, for now, just log
          console.warn("loadParticipantData: cookiePid or cookieParticipantId is missing");
        }
      } catch (err) {
        handleError(err, 'Error loading participant data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    retrieveParticipantCookies();
    // eslint-disable-next-line
  }, []);
  // Used to Re-run effect when participantId or pid changes

  useEffect(() => {
    if (!!participant?.registrationStep && participant?.registrationStep !== activeStep) {
      setLoading(true);
      console.debug("setting activeStep to %s", participant.registrationStep);
      setActiveStep(participant.registrationStep);
      setLoading(false);
    }
  }, [activeStep, participant]);

  // Function to handle Prolific ID submission and load the participant with pid or create a new one
  const storePid = async () => {
    setLoading(true);
    // Check if Prolific ID is valid
    if (!pid || pid.length < 23) {
      handleError(null, 'Invalid Prolific ID, Please enter your Prolific ID');
      return;
    }
    try {
      // Create or find participant and get their ID
      const newParticipantId = await findOrCreateNewParticipant(pid, projectId);
      // Fetch complete participant data
      const participantData = await findParticipantById(newParticipantId);
      if (participantData?.fitbitData?.accessToken?.length > 0) {
        console.debug("storePid: participantData.fitbitData.accessToken:", participantData?.fitbitData?.accessToken);
      }
      // Set participant ID in state
      setParticipantId(newParticipantId);
      // Set cookie here when user explicitly submits
      setCookie('pid', pid, 7);
      setCookie('participantId', newParticipantId);
      // if participantData exists, set it in state
      if (participantData) {
        setParticipant(participantData);
        setActiveStep(participantData.registrationStep);
      }
      // check how setSuccess works
      addAlert('success', 'Successfully stored Prolific ID');
    } catch (err) {
      handleError(err, 'Error registering your Prolific ID. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to initiate Fitbit authorization
  const initiateFitbitAuth = async () => {
    setLoading(true);
    try {
      // Check projectId, pid, and participantId are set in the cookies
      setCookie('projectId', projectId);
      setCookie('pid', pid);
      setCookie('participantId', participantId);
      const generateFitbitAuthData = httpsCallable(functions, 'generateVerifierAndChallenge');
      const result = await generateFitbitAuthData({ pid, participantId });

      if (result?.data?.redirectUri) {
        window.location.href = result.data.redirectUri;
      } else {
        handleError(null, 'Error initiating Fitbit authorization. Please try again.');
      }
    } catch (err) {
      handleError(err, 'Error initiating Fitbit authorization. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Update the registration step for the participant using the backend
  const updateRegistrationStep = async (step, additionalData = {}) => {
    setLoading(true);
    try {
      const updatedParticipant = await updateParticipantRegistration(participantId, step, additionalData);
      if (updatedParticipant) {
        setParticipant(updatedParticipant);
        setActiveStep(step);
      }
    } catch (err) {
      handleError(err, 'Error updating registration step. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Renew the registration process
  const renewRegistration = async () => {
    setLoading(true);
    try {
      console.debug("renewRegistration: pid: %s, participantId: %s", pid, participantId);
      // Clear cookies and reset state
      clearCookies(['pid', 'participantId']);
      setActiveStep(0);
      setPid(null);
      setParticipantId(null);
      setParticipant(null);
      console.debug("renewReg cleared cookies and reset state");
      addAlert('success', 'Registration process has been reset.');
      // Redirect to the registration page
      navigate(`/reg/prolificRegistration/${projectId}` , {replace: true});
      window.location.href = `/reg/prolificRegistration/${projectId}`;
    } catch (err) {
      handleError(err, 'Error renewing registration process. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const getRegistrationUrl = () => {
    const cookie_projectId = getCookie('projectId');
    const cookie_pid = getCookie('pid');
    const cookie_participantId = getCookie('participantId');
    console.log("getRegistrationUrl: cookie_projectId: %s, cookie_pid: %s, cookie_participantId: %s", cookie_projectId, cookie_pid, cookie_participantId);
    console.log("getRegistrationUrl: projectId: %s, pid: %s, participantId: %s", projectId, pid, participantId);
    if ((!projectId && !cookie_projectId) || (!pid && !cookie_pid)) {
      console.error("getRegistrationUrl: projectId or pid is missing, cannot generate URL");
      return null;
    }
    if (projectId?.length > 0 && projectId !== cookie_projectId) {
      console.warn("getRegistrationUrl: projectId is different from cookie_projectId, we should be setting projectId to cookie_projectId");
      setCookie('projectId', projectId);
    }
    const projId = cookie_projectId || projectId;
    const pId = cookie_pid || pid;
    const uri = `/reg/prolificRegistration/${projId}${pId ? '/pId/' + pId : ''}`;
    console.debug("getRegistrationUrl: ouput: %s", uri);
    return uri;
  };

  return (
    <RegistrationContext.Provider
      value={{
        pid,
        setPid,
        projectId,
        participantId,
        participant,
        setParticipant,
        activeStep,
        loading,
        setLoading,
        storePid,
        initiateFitbitAuth,
        updateRegistrationStep,
        renewRegistration,
        getRegistrationUrl,
        handleError,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

// Define prop types for RegistrationProvider
RegistrationProvider.propTypes = {
  children: PropTypes.node.isRequired, // Prop type validation for children
};
