import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, CircularProgress, Container, FormControlLabel, List, ListItem, Typography } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useAlert } from '../context/AlertContext';
import { useRegistration } from '../context/RegistrationContext';
import { functions } from '../firebase';
// import { useParticipantData } from '../hooks/useParticipantData';

/**
 * FitbitCallback component handles the callback from Fitbit's OAuth process.
 * It retrieves the authorization code and state from the URL, fetches the participant data,
 * and allows the user to agree to terms and proceed.
 */
function FitbitCallback() {
  const {
    pid,
    participantId,
    setParticipant,
    loading,
    setLoading,
    getRegistrationUrl,
    handleError,
  } = useRegistration();
  const [searchParams] = useSearchParams();
  const { addAlert } = useAlert();

  // Retrieve authorization code and state from URL parameters
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  // Use the custom hook to fetch participant data
  // const [loadingParticipant] = useParticipantData(participantId);

  // State variable to store the user's agreement to terms
  const [agreeTerms, setAgreeTerms] = useState(false);

  /**
   * Handles the completion of Fitbit authorization.
   *
   * This function performs the following steps:
   * 1. Checks if the loading state is true, if so, it returns early.
   * 2. Sets the loading state to true.
   * 3. Checks if the user has agreed to the terms and conditions, if not, it adds an alert and returns early.
   * 4. Calls the Firebase function to handle the authorization redirect.
   * 5. If the authorization is successful, it sets the participant data and adds a success alert.
   * 6. If the authorization fails, it logs the error, sets the error state, and adds an error alert.
   * 7. Finally, it sets the loading state to false and navigates to the next page.
   *
   * @async
   * @function handleCompleteFitbitAuth
   * @returns {Promise<void>} A promise that resolves when the Fitbit authorization process is complete.
   */
  const handleCompleteFitbitAuth = async () => {
    console.debug("handleCompleteFitbitAuth, code, state, pid, participantId", code, state, pid, participantId);
    // if (loading || loadingParticipant) return;
    if(loading) return;

    setLoading(true);

    if (!agreeTerms) {
      handleError(null, 'Please agree to the terms and conditions');
      return;
    }

    try {
      console.log("handleCompleteFitbitAuth, code, state, pid, participantId", code, state, pid, participantId);
      // Call the Firebase function to handle the authorization redirect
      const handleAuthorizationRedirect = httpsCallable(functions, 'handleAuthorizationRedirect');
      const res = await handleAuthorizationRedirect({ code: code, state: state, pid: pid, participantId: participantId });
      console.debug("handleAuthorizationRedirect, res", res);
      if (res?.data?.participant?.fitbitData?.access_token) {
        setParticipant(res.data.participant);
        addAlert("success", "Fitbit authorization completed successfully");
        const redirect_uri = getRegistrationUrl();
        console.debug("handleCompleteFitbitAuth, redirect_uri", redirect_uri);
        if (!redirect_uri) {
          handleError(null, 'Failed to get registration URL');
        } else {
          window.location.href=redirect_uri;
          //navigate(redirect_uri);
        }
      } else {
        handleError(null, 'Fitbit authorization failed');
        setLoading(false);
        return;
      }
    } catch (err) {
      const completeError = JSON.stringify(err, null, 5);
      console.debug("handleCompleteFitbitAuth, err", completeError);
      if (err.code && err.details && err.details.key) {
        console.error(`Error key: ${err.details.key}, message: ${err.message}`);
        if(err.details.key === 'already-exists') {
          addAlert('error', 'This Fitbit account is already connected to another participant');
          setLoading(false);
          return;
        }
        addAlert('error', `Error: ${err.details.key} - ${err.message}`);
      } else {
        console.error('Unknown error:', err);
        addAlert('error', 'An unknown error occurred');
      }
      handleError(err, 'Fitbit authorization failed');
    } finally {
      setLoading(false);
      // Navigate back to the registration page, with the participant ID if available
      const redirect_uri = getRegistrationUrl();
      if (!redirect_uri) {
        handleError(null, 'Failed to get registration URL');
      } else {
        // TODO: deciced. let's just use window.location.href for now
        // window.location.href = redirect_uri;
        // navigate(redirect_uri);
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <Card sx={{padding: 4}} elevation={3}>
        <CardHeader title="Consent" />
        <CardContent>
          {(loading) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
              <CircularProgress />
            </Box>
          )}
          <Typography variant="body1">
            Hereby, I give my consent to anonymously share my Fitbit wearable data for this study.
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 2 }}>
            <ListItem key={1} sx={{ display: 'list-item', py: 0 }}>
              <Typography>
                I understand that my data will be kept confidential and used for research purposes only (<Link to="/privacy">Privacy Policy</Link>).
              </Typography>
            </ListItem>
            <ListItem key={2} sx={{ display: 'list-item', py:0 }}>
              <Typography>
                I understand that I can withdraw my consent at any time (<Link to="/terms">Terms of Service</Link>).
              </Typography>
            </ListItem>
          </List>
          <FormControlLabel sx={{ mt: 0 }}
            control={<Checkbox value="accept" required
              onChange={() => setAgreeTerms(!agreeTerms)} color="primary" checked={agreeTerms} />}
            label="I accept the Terms of Service and Privacy Policy."
          />
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button disabled={!agreeTerms || loading} variant="contained"
              color="primary" onClick={handleCompleteFitbitAuth}>
            Agree
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}

export default FitbitCallback;