/**
 * Retrieves the value of a specified cookie by name.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string} The value of the cookie if found, otherwise an empty string.
 */
// Function to retrieve a cookie value by name
export const getCookie = (name) => {
  // Get all cookies as an array
  const cookies = document.cookie ? document.cookie.split(';') : [];
  // Variable to hold the cookie value
  let cookieValue = null;
  // Loop through the cookies
  for (let i = 0; i < cookies.length; i++) {
    // Get the current cookie and trim whitespace
    const cookie = cookies[i].trim();
    // Check if the cookie name matches
    if (cookie.substring(0, name.length + 1) === (name + '=')) {
      // Decode and assign the cookie value
      cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
      break;
    }
  }
  // Return the cookie value or null
  return cookieValue;
}

/**
 * Sets a cookie with the specified name, value, and expiration time.
 * Includes security attributes and proper value encoding.
 *
 * @param {string} name - The name of the cookie
 * @param {string} value - The value of the cookie
 * @param {number} [expiresInDays=3] - Days until cookie expires
 * @returns {boolean} - Success status of cookie setting
 */
export const setCookie = (name, value, expiresInDays = 30) => {
  try {
    // Validate inputs
    if (!name || !value) {
      console.error('Cookie name and value are required');
      return false;
    }

    // Calculate expiration
    const expires = new Date(Date.now() + expiresInDays * 86400e3).toUTCString();

    // Encode value to handle special characters
    const encodedValue = encodeURIComponent(value.trim());

    // Set cookie with security attributes
    document.cookie = `${name}=${encodedValue}; `
      + `path=/; `
      + `expires=${expires}; `
      + `SameSite=Strict; `
      + (window.location.protocol === 'https:' ? 'Secure;' : '');

    // Verify cookie was set
    return document.cookie.includes(`${name}=`);

  } catch (err) {
    console.error('Error setting cookie:', err);
    return false;
  }
};

/**
 * Clears cookies with the specified names.
 * Sets expiration in the past to remove cookies.
 * @param {Set<string>} names - Set of cookie names to clear
 * @returns {boolean} - Success status of cookie clearing
 */
export const clearCookies = (names) => {
  try {
    // For all names in names Set cookie with expiration in the past to clear it
    names.forEach((name) => {
      document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict; Secure;`;
    });
    return true;
  } catch (err) {
    console.error('Error clearing cookie:', err);
    return false;
  }
};

